<template>
  <div>
    <MobileDefault v-if="isMobile">
      <div class="container-bg-white container-space-mobile-default">
        <div class="flex flex-col align-center">
          <h1 class="text-xl font-medium text-gray-900 text-center">Gói cước dành cho Doanh nghiệp của bạn</h1>
          <a-select v-model="pricingPlanDurationWrap" style="width: 260px" class="mb-1">
            <a-select-option :value="PRICING_PLAN_DURATION.sixMonths.id">Trả theo Tháng</a-select-option>
            <a-select-option :value="PRICING_PLAN_DURATION.twelveMonths.id">
              Trả theo Năm
              <a-icon type="arrow-left" class="text-green-500"/>
              <span class="text-green-500">{{PRICING_PLAN_DURATION.twelveMonths.promotedText}}</span>
            </a-select-option>
          </a-select>
          <template v-for="pricing_plan in PRICING_PLANS">
            <div :key="pricing_plan.id"
                 class="w-full mt-6 border border-gray-200 rounded-lg p-3"
            >
              <div class="flex justify-space-between">
                <span class="text-sm leading-6 uppercase">{{ pricing_plan.name }}</span>
                <span class="text-gray-500 flex items-center text-xs">
<!--                  <a-icon type="user" class="mr-1"/>-->
                  <svg height="14" width="14" viewBox="0 0 14 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.00016 2.3335C7.619 2.3335 8.21249 2.57933 8.65008 3.01691C9.08766 3.4545 9.3335 4.04799 9.3335 4.66683C9.3335 5.28567 9.08766 5.87916 8.65008 6.31675C8.21249 6.75433 7.619 7.00016 7.00016 7.00016C6.38132 7.00016 5.78783 6.75433 5.35025 6.31675C4.91266 5.87916 4.66683 5.28567 4.66683 4.66683C4.66683 4.04799 4.91266 3.4545 5.35025 3.01691C5.78783 2.57933 6.38132 2.3335 7.00016 2.3335V2.3335ZM7.00016 8.16683C9.5785 8.16683 11.6668 9.211 11.6668 10.5002V11.6668H2.3335V10.5002C2.3335 9.211 4.42183 8.16683 7.00016 8.16683Z" fill="#919191"/>
                  </svg>
                  <span v-if="ALL_FEATURES.numberOfAccount.availablePlan[pricing_plan.id] !== unlimitedSupport">{{ALL_FEATURES.numberOfAccount.availablePlan[pricing_plan.id]}}</span>
                  <svg v-else class="text-gray-500" width="15" height="9" viewBox="0 0 15 9" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.3438 0.75C9.53906 0.75 8.17969 2.36719 7.5 3.39844C6.79688 2.36719 5.46094 0.75 3.63281 0.75C1.61719 0.75 0 2.4375 0 4.5C0 6.58594 1.61719 8.25 3.63281 8.25C5.46094 8.25 6.79688 6.65625 7.5 5.625C8.17969 6.65625 9.53906 8.25 11.3438 8.25C13.3594 8.25 15 6.58594 15 4.5C15 2.4375 13.3594 0.75 11.3438 0.75ZM3.63281 7.125C2.25 7.125 1.125 5.95312 1.125 4.5C1.125 3.07031 2.25 1.875 3.63281 1.875C5.22656 1.875 6.46875 3.82031 6.84375 4.5C6.46875 5.20312 5.22656 7.125 3.63281 7.125ZM11.3438 7.125C9.75 7.125 8.50781 5.20312 8.13281 4.5C8.53125 3.82031 9.75 1.875 11.3438 1.875C12.7266 1.875 13.875 3.07031 13.875 4.5C13.875 5.95312 12.7266 7.125 11.3438 7.125Z"/>
                  </svg>
                </span>
              </div>
              <div class="pr-8"><p class="text-xs text-gray-500">{{ pricing_plan.description }}</p></div>
              <div class="mt-4 flex items-center" v-if="isPrice(pricing_plan.price[pricingPlanDuration])">
                <div class="text-xl font-bold text-gray-900">{{ pricing_plan.price[pricingPlanDuration] | formatSortTextCurrency(false)}}</div>
                <div v-if="isPrice(pricing_plan.price[pricingPlanDuration])" class="ml-0.5 text-base font-medium text-gray-500">
                  <span class="mx-1">/</span>
                  <a-select v-model="pricingPlanDuration" style="min-width: 90px">
                    <template v-for="planDuration in PRICING_PLAN_DURATION">
                      <a-select-option :value="planDuration.id" :key="planDuration.id" v-if="isPrice(pricing_plan.price[planDuration.id])">
                        {{planDuration.label}}
                      </a-select-option>
                    </template>
                  </a-select>
                </div>
              </div>
              <p class="text-green-500">{{pricing_plan.promotedText[pricingPlanDuration]}}</p>
              <div class="text-xs my-2" style="height: 16px;">
                <span
                  v-if="pricing_plan.id !== PRICING_PLANS.market_default.id"
                  class="underline cursor-pointer"
                  @click="handleClickFreeTrial"
                >
                  Liên hệ để dùng thử miễn phí
                </span>
              </div>
              <div class="mt-5 mb-5 flex justify-center">
                <p @click="onClickBtnBuy(pricing_plan.types[pricingPlanDuration])"
                   v-if="pricing_plan.types[pricingPlanDuration] && pricing_plan.id !== PRICING_PLANS.market_default.id"
                   style="width: 200px;"
                   class="cursor-pointer block rounded-md py-2 text-sm font-medium text-center"
                   :class="`${!isPrice(pricing_plan.price[pricingPlanDuration]) ? 'bg-white border text-primary-500' : 'bg-primary-800 text-white'}`"
                   :style="`${!isPrice(pricing_plan.price[pricingPlanDuration]) ? 'background-color: transparent; color: #F55800;' : 'background-color: #F55800; color: white;'}`"
                >
                  <a-icon v-if="planTypeCreating === pricing_plan.types[pricingPlanDuration]" type="loading"/>
                  <template v-if="!isPrice(pricing_plan.price[pricingPlanDuration])">
                    Liên hệ dùng thử
                  </template>
                  <template v-else>
                    Mua {{ pricing_plan.name }}
                  </template>
                </p>
              </div>
              <!--              <p v-if="isPrice(pricing_plan.price[pricingPlanDuration])" class="text-right mb-2" style="color: #FF8844; font-size: 10px">Chi tiết tính năng ></p>-->
            </div>
          </template>
          <template v-for="pricing_plan in marketv2_enterprise">
            <div :key="pricing_plan.id"
                 class="w-full mt-6 border border-gray-200 rounded-lg p-3"
            >
              <div class="flex justify-space-between">
                <span class="text-sm leading-6 uppercase">{{ pricing_plan.name }}</span>
                <span class="text-gray-500 flex items-center text-xs">
<!--                  <a-icon type="user" class="mr-1"/>-->
                  <svg height="14" width="14" viewBox="0 0 14 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.00016 2.3335C7.619 2.3335 8.21249 2.57933 8.65008 3.01691C9.08766 3.4545 9.3335 4.04799 9.3335 4.66683C9.3335 5.28567 9.08766 5.87916 8.65008 6.31675C8.21249 6.75433 7.619 7.00016 7.00016 7.00016C6.38132 7.00016 5.78783 6.75433 5.35025 6.31675C4.91266 5.87916 4.66683 5.28567 4.66683 4.66683C4.66683 4.04799 4.91266 3.4545 5.35025 3.01691C5.78783 2.57933 6.38132 2.3335 7.00016 2.3335V2.3335ZM7.00016 8.16683C9.5785 8.16683 11.6668 9.211 11.6668 10.5002V11.6668H2.3335V10.5002C2.3335 9.211 4.42183 8.16683 7.00016 8.16683Z" fill="#919191"/>
                  </svg>
                  <span v-if="ALL_FEATURES.numberOfAccount.availablePlan[pricing_plan.id] !== unlimitedSupport">{{ALL_FEATURES.numberOfAccount.availablePlan[pricing_plan.id]}}</span>
                  <svg v-else class="text-gray-500" width="15" height="9" viewBox="0 0 15 9" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.3438 0.75C9.53906 0.75 8.17969 2.36719 7.5 3.39844C6.79688 2.36719 5.46094 0.75 3.63281 0.75C1.61719 0.75 0 2.4375 0 4.5C0 6.58594 1.61719 8.25 3.63281 8.25C5.46094 8.25 6.79688 6.65625 7.5 5.625C8.17969 6.65625 9.53906 8.25 11.3438 8.25C13.3594 8.25 15 6.58594 15 4.5C15 2.4375 13.3594 0.75 11.3438 0.75ZM3.63281 7.125C2.25 7.125 1.125 5.95312 1.125 4.5C1.125 3.07031 2.25 1.875 3.63281 1.875C5.22656 1.875 6.46875 3.82031 6.84375 4.5C6.46875 5.20312 5.22656 7.125 3.63281 7.125ZM11.3438 7.125C9.75 7.125 8.50781 5.20312 8.13281 4.5C8.53125 3.82031 9.75 1.875 11.3438 1.875C12.7266 1.875 13.875 3.07031 13.875 4.5C13.875 5.95312 12.7266 7.125 11.3438 7.125Z"/>
                  </svg>
                </span>
              </div>
              <div class="pr-8"><p class="text-xs text-gray-500">{{ pricing_plan.description }}</p></div>
              <div class="mt-4 flex items-center" v-if="isPrice(pricing_plan.price[pricingPlanDuration])">
                <div class="text-xl font-bold text-gray-900">{{ pricing_plan.price[pricingPlanDuration] | formatSortTextCurrency(false)}}</div>
                <div v-if="isPrice(pricing_plan.price[pricingPlanDuration])" class="ml-0.5 text-base font-medium text-gray-500">
                  <span class="mx-1">/</span>
                  <a-select v-model="pricingPlanDuration" style="min-width: 100px">
                    <template v-for="planDuration in PRICING_PLAN_DURATION">
                      <a-select-option :value="planDuration.id" :key="planDuration.id" v-if="isPrice(pricing_plan.price[planDuration.id])">
                        {{planDuration.label}}
                      </a-select-option>
                    </template>
                  </a-select>
                </div>
              </div>
              <p class="text-green-500">{{pricing_plan.promotedText[pricingPlanDuration]}}</p>
              <div class="text-xs my-2" style="height: 16px;">
                <span
                  v-if="pricing_plan.id !== PRICING_PLANS.market_default.id"
                  class="underline cursor-pointer"
                  @click="handleClickFreeTrial"
                >
                  Liên hệ để dùng thử miễn phí
                </span>
              </div>
              <div class="mt-5 mb-5 flex justify-center">
                <p @click="onClickBtnBuy(pricing_plan.types[pricingPlanDuration])"
                   v-if="pricing_plan.types[pricingPlanDuration]"
                   style="width: 200px;"
                   class="cursor-pointer block rounded-md py-2 text-sm font-medium text-center"
                   :class="`${!isPrice(pricing_plan.price[pricingPlanDuration]) ? 'bg-white border text-primary-500' : 'bg-primary-800 text-white'}`"
                   :style="`${!isPrice(pricing_plan.price[pricingPlanDuration]) ? 'background-color: transparent; color: #F55800;' : 'background-color: #F55800; color: white;'}`"
                >
                  <a-icon v-if="planTypeCreating === pricing_plan.types[pricingPlanDuration]" type="loading"/>
                  <template v-if="!isPrice(pricing_plan.price[pricingPlanDuration])">
                    Liên hệ dùng thử
                  </template>
                  <template v-else>
                    Mua {{ pricing_plan.name }}
                  </template>
                </p>
              </div>
              <!--              <p v-if="isPrice(pricing_plan.price[pricingPlanDuration])" class="text-right mb-2" style="color: #FF8844; font-size: 10px">Chi tiết tính năng ></p>-->
            </div>
          </template>
        </div>
      </div>
      <!--      <a-modal :closable="true"-->
      <!--               :maskClosable="false"-->
      <!--               :footer="null"-->
      <!--               v-model="showPopup"-->
      <!--               @cancel="onClickCancel"-->
      <!--               :width="600">-->
      <!--        <p slot="title">Chi tiết tính năng</p>-->
      <!--      </a-modal>-->
    </MobileDefault>
    <Default v-else>
      <div class="container-bg-white container-space-default border-solid">
        <div class="mx-auto pt-8 pb-4">
          <div class="flex flex-col align-center">
            <h1 class="text-6xl font-normal text-primary-500 text-center">Giá Gói Sản Phẩm</h1>
            <div class="relative self-center bg-gray-100 rounded-lg p-0.5 flex mt-8">
              <button type="button"
                      class="cursor-pointer py-2 text-sm font-medium relative w-1/2 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:z-10 sm:w-auto px-8"
                      :class="isMonthlyPlans ? 'bg-white border-gray-200 rounded-md shadow-sm text-gray-900 shadow-md' : 'ml-0.5 border border-transparent rounded-md text-gray-700'"
                      @click="pricingPlanDuration = PRICING_PLAN_DURATION.sixMonths.id"
              >
                Trả theo Tháng
              </button>
              <button type="button"
                      class="cursor-pointer py-2 text-sm font-medium relative w-1/2 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:z-10 sm:w-auto px-8"
                      :class="isYearlyPlans ? 'bg-white border-gray-200 rounded-md shadow-sm text-gray-900 shadow-md' : 'ml-0.5 border border-transparent rounded-md text-gray-700'"
                      @click="pricingPlanDuration = PRICING_PLAN_DURATION.twelveMonths.id"
              >
                Trả theo Năm
              </button>
              <div
                class="absolute d-inline-flex text-green-500"
                style="top: 105%; left: 60%; white-space: nowrap;"
              >
                {{PRICING_PLAN_DURATION.twelveMonths.promotedText}}
              </div>
            </div>
          </div>
          <div class="mt-12 mt-16 space-y-0 grid grid-cols-2 gap-2 md:grid-cols-3 md:max-w-4xl md:mx-auto md:gap-2 xl:grid-cols-5 xl:gap-2 max-w-none mx-0">
            <template v-for="pricing_plan in PRICING_PLANS">
              <div :key="pricing_plan.id" class="border border-gray-200 rounded-lg hover:shadow-md shadow-sm">
                <div class="p-4 xxl:p-6 border-b border-gray-200">
                  <h2 class="uppercase text-lg leading-6 font-medium text-gray-900">{{ pricing_plan.name }}</h2>
                  <!--                <div style="height: 90px"><p class="mt-4 text-sm text-gray-500">{{ pricing_plan.description }}</p></div>-->
                  <div style="height: 40px" class="mt-8" :class="pricingPlanDuration === PRICING_PLAN_DURATION.trial.id ? '' : 'flex items-end'">
                    <template v-if="pricing_plan.price[pricingPlanDuration]">
                      <div :class="isPrice(pricing_plan.price[pricingPlanDuration]) ? 'text-xl font-extrabold text-gray-900' : 'leading-10 text-xl font-bold text-gray-700'">
                        <span v-if="![CONTACT_US_STATE, NOT_AVAILABLE_STATE].includes(pricing_plan.price[pricingPlanDuration])">
                          {{ pricing_plan.price[pricingPlanDuration] | formatSortTextCurrency(false)}}
                        </span>
                            <span v-else-if="pricing_plan.fixedYearPrice && pricingPlanDuration === PRICING_PLAN_DURATION.twelveMonths.id">
                          {{pricing_plan.fixedYearPrice}} / năm
                        </span>
                      </div>
                      <div v-if="isPrice(pricing_plan.price[pricingPlanDuration])" class="ml-0.5 text-base font-medium text-gray-500">
                        <span class="mx-1" v-if="pricingPlanDuration !== PRICING_PLAN_DURATION.trial.id">/</span>
                        <a-select v-model="pricingPlanDuration" style="min-width: 100px">
                          <template v-for="planDuration in PRICING_PLAN_DURATION">
                            <a-select-option :value="planDuration.id" :key="planDuration.id" v-if="isPrice(pricing_plan.price[planDuration.id])">{{planDuration.label}}</a-select-option>
                          </template>
                        </a-select>
                      </div>
                    </template>
                  </div>
                  <p style="height: 24px" class="text-green-500">
                    {{pricing_plan.promotedText[pricingPlanDuration]}}
                  </p>
                  <div class="text-xs my-2" style="height: 16px;">
                    <span
                      v-if="pricing_plan.id !== PRICING_PLANS.market_default.id"
                      class="underline cursor-pointer"
                      @click="handleClickFreeTrial"
                    >
                      Liên hệ để dùng thử miễn phí
                    </span>
                  </div>
                  <div style="height: 38px" class="mt-2">
                    <template
                      v-if="(pricing_plan.price[pricingPlanDuration] && NOT_AVAILABLE_STATE !== pricing_plan.price[pricingPlanDuration]) || [PRICING_PLANS.marketv2_advanced.id, PRICING_PLANS.marketv2_premium.id].includes(pricing_plan.id)"
                    >
                      <p @click="onClickBtnBuy(pricing_plan.types[pricingPlanDuration])"
                         class="cursor-pointer block w-full border border-primary-800 rounded-md py-2 text-sm font-semibold hover:text-gray-900 text-center hover:bg-primary-500"
                         :class="`${[PRICING_PLANS.marketv2_advanced.id, PRICING_PLANS.marketv2_premium.id].includes(pricing_plan.id) || [CONTACT_US_STATE].includes(pricing_plan.price[pricingPlanDuration]) ? 'bg-white text-primary-500' : 'bg-primary-800 text-white'}`"
                      >
                        <a-icon v-if="planTypeCreating === pricing_plan.types[pricingPlanDuration]" type="loading"/>
                        <template v-if="[PRICING_PLANS.marketv2_advanced.id, PRICING_PLANS.marketv2_premium.id].includes(pricing_plan.id)">
                          {{ CONTACT_US_STATE }} dùng thử
                        </template>
                        <template v-else-if="isContactUs(pricing_plan)">
                          {{ CONTACT_US_STATE }} {{[PRICING_PLANS.marketv2_advanced.id, PRICING_PLANS.marketv2_premium.id].includes(pricing_plan.id) ? 'dùng thử' : ''}}
                        </template>
                        <template v-else>
                          Mua gói {{ pricing_plan.name }}
                        </template>
                      </p>
                    </template>
                  </div>
                  <!--                <p style="height: 24px; color: #71a7ff;" class="text-center"><span v-if="pricing_plan.fixedYearPrice">({{pricing_plan.fixedYearPrice}}/năm)</span></p>-->
                </div>
                <div class="pb-4 p-3 xxl:pb-8 xxl:p-6">
                  <!--                <h3 class="text-xs font-medium text-gray-900 tracking-wide uppercase">Giá trị nổi bật</h3>-->
                  <ul class="mt-6 space-y-4 padding-left-0">
                    <li v-for="feature in pricing_plan.listValue" :key="feature.name" class="flex space-x-3 padding-bottom-10">
                      <svg class="flex-shrink-0 h-5 w-5 text-green-500" x-description="Heroicon name: solid/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                      </svg>
                      <span class="text-sm text-gray-500 padding-left-10" v-html="feature"></span>
                    </li>
                  </ul>
                </div>
              </div>
            </template>
          </div>
        </div>
        <table class="w-full h-px table-fixed">
          <caption class="sr-only">
            Pricing plan comparison
          </caption>
          <thead>
          <tr>
            <th class="w-full pb-4 px-6 text-sm font-medium text-gray-900 text-left" scope="col">
              <div class="">
                <p class="text-xl text-primary-500 mb-2">Gói tuỳ chỉnh <span class="uppercase">enterprise</span></p>
                <p
                  class="bg-white text-primary-500 cursor-pointer block w-full border border-primary-800 rounded-md py-2 text-sm font-semibold hover:text-gray-900 text-center hover:bg-primary-500"
                  @click="onClickBtnBuy(enterprisePlanType)"
                >
                  {{CONTACT_US_STATE}}
                </p>
              </div>
            </th>
            <template v-for="pricing_plan in pricingPlanDetailGroup">
              <th :key="pricing_plan.name" class="uppercase w-full pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">{{ pricing_plan.name }}</th>
            </template>
          </tr>
          </thead>
          <tbody class="border-t border-gray-200 divide-y divide-gray-200">
          <tr>
            <th class="w-full bg-gray-50 py-3 pl-6 text-sm font-medium text-gray-900 text-left" colspan="5" scope="colgroup">Tính năng</th>
          </tr>
          <template v-for="feature in displayFeatures">
            <tr v-if="feature.isRelease" :key="feature.name">
              <th class="py-5 px-6 text-sm font-normal text-gray-500 text-left" scope="row">{{ feature.description }}</th>
              <template v-for="pricing_plan in pricingPlanDetailGroup">
                <td :key="pricing_plan.name" class="py-5 px-6">
                  <template v-if="Array.isArray(feature.availablePlan[pricing_plan.id])">
                    <p v-for="(detail, index) in feature.availablePlan[pricing_plan.id]" :key="index">
                      <span v-if="typeof detail === 'string'" class="block" :class="index === 0 ? 'text-sm text-gray-700' : 'text-xs text-gray-500'">{{ detail }}</span>
                      <template v-else>
                        <svg v-if="detail === true" class="h-5 w-5 text-green-500" x-description="Heroicon name: solid/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                        </svg>
                        <svg v-else class="h-5 w-5 text-gray-400" x-description="Heroicon name: solid/minus" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path>
                        </svg>
                        <span class="sr-only">{{ detail === true ? 'Hỗ trợ trong' : 'Không hỗ trợ trong' }} in {{ pricing_plan.name }}</span>
                      </template>
                    </p>
                  </template>
                  <span v-else-if="typeof feature.availablePlan[pricing_plan.id] === 'string'" class="block text-sm text-gray-700">{{ feature.availablePlan[pricing_plan.id] }}</span>
                  <template v-else>
                    <svg v-if="feature.availablePlan[pricing_plan.id] === true" class="h-5 w-5 text-green-500" x-description="Heroicon name: solid/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                    </svg>
                    <svg v-else class="h-5 w-5 text-gray-400" x-description="Heroicon name: solid/minus" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path>
                    </svg>
                    <span class="sr-only">{{ feature.availablePlan[pricing_plan.id] === true ? 'Hỗ trợ trong' : 'Không hỗ trợ trong' }} in {{ pricing_plan.name }}</span>
                  </template>
                </td>
              </template>
            </tr>
          </template>
          </tbody>
          <tfoot>
          <tr class="border-t border-gray-200">
            <td class="">
              <div class="pt-5 px-6">
                <p class="text-xl text-primary-500" style="height: 88px">Gói tuỳ chỉnh <span class="uppercase">enterprise</span></p>
                <p
                  class="mt-2 bg-white text-primary-500 cursor-pointer block w-full border border-primary-800 rounded-md py-2 text-sm font-semibold hover:text-gray-900 text-center hover:bg-primary-500"
                  @click="onClickBtnBuy(enterprisePlanType)"
                >
                  {{CONTACT_US_STATE}}
                </p>
              </div>
            </td>
            <template v-for="pricing_plan in pricingPlanDetailGroup">
              <td :key="pricing_plan.name" class="pt-5 px-6">
                <div :class="pricingPlanDuration === PRICING_PLAN_DURATION.trial.id ? '' : 'flex items-end'" v-if="pricing_plan.price[pricingPlanDuration]" style="width: calc(100% + 20px); height: 40px;">
                  <div class="" :class="isPrice(pricing_plan.price[pricingPlanDuration]) ? 'text-2xl font-extrabold text-gray-900' : 'leading-8 text-lg font-semibold text-gray-700'">
                  <span v-if="![CONTACT_US_STATE, NOT_AVAILABLE_STATE].includes(pricing_plan.price[pricingPlanDuration])">
                    {{ pricing_plan.price[pricingPlanDuration] | formatSortTextCurrency(false)}}
                  </span>
                    <span v-else-if="pricing_plan.fixedYearPrice && pricingPlanDuration === PRICING_PLAN_DURATION.twelveMonths.id">
                      {{pricing_plan.fixedYearPrice}} / năm
                    </span>
                  </div>
                  <div v-if="isPrice(pricing_plan.price[pricingPlanDuration])" class="ml-0.5 text-base font-medium text-gray-500">
                    <span class="mx-1" v-if="pricingPlanDuration !== PRICING_PLAN_DURATION.trial.id">/</span>
                    <a-select v-model="pricingPlanDuration" style="min-width: 100px">
                      <template v-for="planDuration in PRICING_PLAN_DURATION">
                        <a-select-option :value="planDuration.id" :key="planDuration.id" v-if="isPrice(pricing_plan.price[planDuration.id])">{{planDuration.label}}</a-select-option>
                      </template>
                    </a-select>
                  </div>
                </div>
                <p style="height: 24px" class="text-green-500">{{pricing_plan.promotedText[pricingPlanDuration]}}</p>
                <div class="text-xs my-2" style="height: 16px;">
                    <span
                      class="underline cursor-pointer"
                      @click="handleClickFreeTrial"
                    >
                      Liên hệ để dùng thử miễn phí
                    </span>
                </div>
                <div style="height: 38px" class="mt-2">
                  <p @click="onClickBtnBuy(pricing_plan.types[pricingPlanDuration])"
                     class="cursor-pointer block w-full border border-primary-800 rounded-md py-2 text-sm font-semibold hover:text-gray-900 text-center hover:bg-primary-500"
                     :class="`${isContactUs(pricing_plan) ? 'bg-white text-primary-500' : 'bg-primary-800 text-white'}`"
                  >
                    <a-icon v-if="planTypeCreating === pricing_plan.types[pricingPlanDuration]" type="loading"/>
                    <template v-if="[PRICING_PLANS.marketv2_advanced.id, PRICING_PLANS.marketv2_premium.id].includes(pricing_plan.id)">
                      {{ CONTACT_US_STATE }} dùng thử
                    </template>
                    <template v-else-if="isContactUs(pricing_plan)">
                      {{ CONTACT_US_STATE }} {{[PRICING_PLANS.marketv2_advanced.id, PRICING_PLANS.marketv2_premium.id].includes(pricing_plan.id) ? 'dùng thử' : ''}}
                    </template>
                    <template v-else>
                      Mua gói {{ pricing_plan.name }}
                    </template>
                  </p>
                </div>
              </td>
            </template>
          </tr>
          </tfoot>
        </table>
      </div>
    </Default>
    <PopupNotification v-if="showPopupNotification === NOTIFICATION_TYPE.upgrade_plan"
                        title="Nâng cấp tài khoản"
                        :contents="upgradePlanContent"
                        :ok-title="'Liên hệ Market BeeCost'"
                        @ok="contactFacebook"
    >
    </PopupNotification>
  </div>
</template>

<script>
import Default from "@/layout/Default";
import {
  ALL_FEATURES,
  ALL_FEATURES_V2,
  ALL_PRICING_PLAN_V2,
  CONTACT_US_STATE,
  NOT_AVAILABLE_STATE,
  PRICING_PLAN_DURATION_V2,
  UNLIMITED_SUPPORT,
} from "@/constant/pricing/PricingPlansConstant";
import {MutationGeneral} from "@/store/modules/GeneralModule/mutation";
import {
  createVoucher,
  extractPlanType,
  extractPlanTypeV2,
  findPlanInfo
} from "@/service/payment/PaymentService";
import {MutationUser} from "@/store/modules/UserModule/mutation";
import {toNumber} from "@/helper/StringHelper";
import {formatSortTextCurrency} from "@/helper/FormatHelper";
import {MESSENGER_ID, NOTIFICATION_TYPE} from "@/constant/general/GeneralConstant";
import {PERMISSION} from "@/constant/general/PermissionConstant";
import PopupNotification from "@/views/popup/PopupNotification";
import {URL_CONTACT} from "@/config/ConfigEndpoint";
import {mixinStoreGetterPermission, mixinStoreGetterUser} from "@/mixin/store/MixinUser";
import {mixinStoreGetterGlobal} from "@/mixin/store/MixinGlobal";
import MobileDefault from "@/layout/MobileDefault";
import {gaTrackEvent} from "@/service/tracking/GaTrackingService";
import {MutationSidebar} from "@/store/modules/SidebarModule/mutation";

export default {
  name: "PricingViewV2",
  components: {
    Default,
    MobileDefault,
    PopupNotification,
  },
  data() {
    const pricingPlan = {
      market_default: ALL_PRICING_PLAN_V2.market_default,
      marketv2_small: ALL_PRICING_PLAN_V2.marketv2_small,
      marketv2_standard: ALL_PRICING_PLAN_V2.marketv2_standard,
      marketv2_advanced: ALL_PRICING_PLAN_V2.marketv2_advanced,
      marketv2_premium: ALL_PRICING_PLAN_V2.marketv2_premium,
    }
    const pricingPlanDetailGroup = {
      marketv2_small: ALL_PRICING_PLAN_V2.marketv2_small,
      marketv2_standard: ALL_PRICING_PLAN_V2.marketv2_standard,
      marketv2_advanced: ALL_PRICING_PLAN_V2.marketv2_advanced,
      marketv2_premium: ALL_PRICING_PLAN_V2.marketv2_premium,
    }
    const marketv2_enterprise = {
      marketv2_enterprise: ALL_PRICING_PLAN_V2.marketv2_enterprise,
    }
    return {
      PRICING_PLAN_DURATION: PRICING_PLAN_DURATION_V2,
      pricingPlanDuration: PRICING_PLAN_DURATION_V2.twelveMonths.id,
      PRICING_PLANS: pricingPlan,
      marketv2_enterprise: marketv2_enterprise,
      enterprisePlanType: ALL_PRICING_PLAN_V2.marketv2_enterprise.types[PRICING_PLAN_DURATION_V2.trial.id],
      pricingPlanDetailGroup: pricingPlanDetailGroup,
      CONTACT_US_STATE,
      NOT_AVAILABLE_STATE,
      ALL_FEATURES: {
        ...ALL_FEATURES,
        ...ALL_FEATURES_V2,
      },
      displayFeatures: ALL_FEATURES_V2,
      NOTIFICATION_TYPE: NOTIFICATION_TYPE,
      planTypeCreating: null,
      isShowPopupNotification: false,
      upgradePlanType: null,
      unlimitedSupport: UNLIMITED_SUPPORT,
      isRelease: false
    }
  },
  mounted() {
    this.$store.commit(`SidebarModule/${MutationSidebar.isCollapsed}`, true)
    let now = new Date()
    const RELEASE_TIME = new Date(2022, 2, 6)
    this.isRelease = now > RELEASE_TIME || this.$route.query.isRelease
    window.scroll(0, 0)
    gaTrackEvent('view_page', this.$route.name, this.email)
  },
  methods: {
    async onClickBtnBuy(planType) {
      this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupSignUpLeadForm}`, true)
      // if (!planType) {
      //   this.$notification['error']({
      //     message: 'Mua gói cước chưa thành công',
      //     description: 'Đã có lỗi xảy ra, vui lòng liên hệ Market Bee để được hỗ trợ',
      //   })
      //   this.planTypeCreating = null
      //   return
      // }
      // this.upgradePlanType = planType
      // let extractPlanObject = extractPlanTypeV2(planType)
      // if (
      //   [
      //     this.PRICING_PLANS.marketv2_advanced.id,
      //     this.PRICING_PLANS.marketv2_premium.id,
      //   ].includes(extractPlanObject.planId)
      //   || this.enterprisePlanType === planType
      // ) {
      //   window.open(URL_CONTACT, '_blank')
      //   return
      // }
      // if (!this.isLogin) {
      //   await this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupLogin}`, true)
      //   return
      // }
      // if (
      //   !this.isExpired
      //   && this.permissionAvailableName !== PERMISSION.market_default.id
      //   && this.permissionAvailableName !== PERMISSION[extractPlanObject.planId].id
      // ) {
      //   await this.$store.dispatch(`${MutationGeneral.MODULE_PATH}/${MutationGeneral.setShowPopupNotification}`, NOTIFICATION_TYPE.upgrade_plan)
      //   return
      // }
      // this.planTypeCreating = planType
      // const voucher = await createVoucher(planType)
      // if (!voucher?.voucher_code) {
      //   this.$notification['error']({
      //     message: 'Tạo gói cước không thành công',
      //     description: 'Đã có lỗi xảy ra, vui lòng thử lại trong giây lát',
      //   })
      //   this.planTypeCreating = null
      //   return
      // }
      // await this.$store.dispatch(`UserModule/${MutationUser.setPercentPaymentProgress}`, 0)
      // await this.$store.dispatch(`UserModule/${MutationUser.setIsSuccessfulPayment}`, null)
      // await this.$store.dispatch(`UserModule/${MutationUser.setVoucherCode}`, voucher.voucher_code)
      // await this.$store.dispatch(`UserModule/${MutationUser.paymentQRCode}`, voucher.payment_qrcode)
      // this.planTypeCreating = null
      // await this.$router.push({name: 'payment', query: {'voucherCode': voucher.voucher_code, 'voucherId': planType}})
    },
    handleClickFreeTrial() {
      window.open(URL_CONTACT, '_blank')
      return
    },
    isPrice(priceString) {
      return !!toNumber(priceString)
    },
    isNotAvailablePlan(priceString) {
      return priceString === 'Không hỗ trợ'
    },
    contactFacebook() {
      window.open(`http://messenger.com/t/${MESSENGER_ID}`, '_blank')
    },
    isContactUs(pricing_plan) {
      return [this.PRICING_PLANS.marketv2_advanced.id, this.PRICING_PLANS.marketv2_premium.id].includes(pricing_plan.id)
        || [CONTACT_US_STATE].includes(pricing_plan.price[this.pricingPlanDuration])
    }
  },
  filters: {
    formatSortTextCurrency
  },
  mixins: [
    mixinStoreGetterGlobal,
    mixinStoreGetterPermission,
    mixinStoreGetterUser
  ],
  computed: {
    upgradePlanId() {
      return extractPlanType(this.upgradePlanType)?.planId
    },
    upgradeAccountName() {
      return PERMISSION[this.upgradePlanId]?.label
    },
    upgradePlanContent() {
      return [
        `Tài khoản của bạn đang là ${this.accountName}`,
        `Để chuyển sang ${this.upgradeAccountName} ` + (this.permissionAvailableName.includes(this.upgradePlanId) ? `(mã ${this.upgradePlanId})` : '') + `, vui lòng liên hệ hỗ trợ`
      ]
    },
    accountName() {
      return PERMISSION[this.$store.state.UserModule[MutationUser.permissionAvailableName]]?.label
    },
    currentPlan() {
      return findPlanInfo(this.permissionAvailableName)
    },
    isMonthlyPlans() {
      let monthlyPlan = [
        this.PRICING_PLAN_DURATION.threeMonths.id,
        this.PRICING_PLAN_DURATION.sixMonths.id,
      ]
      return monthlyPlan.includes(this.pricingPlanDuration)
    },
    isYearlyPlans() {
      let yearlyPlan = [
        this.PRICING_PLAN_DURATION.twelveMonths.id,
      ]
      return yearlyPlan.includes(this.pricingPlanDuration)
    },
    isLogin: {
      get() {
        return this.$store.state.UserModule.isLogin
      }
    },
    showPopupNotification: {
      get() {
        return this.$store.state.GeneralModule.showPopupNotification
      },
      async set(value) {
        await this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupNotification}`, value)
      }
    },
    pricingPlanDurationWrap: {
      get() {
        if (this.isMonthlyPlans) {
          return this.PRICING_PLAN_DURATION.sixMonths.id
        }
        return this.pricingPlanDuration
      },
      async set(value) {
        this.pricingPlanDuration = value
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.decrease-focus {
  opacity: 50%;
}
</style>
